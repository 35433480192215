import * as constants from "../constants";
import GC from "@grapecity/spread-sheets";

export async function CarbonCredit(baseapi, body) {
    const response = await baseapi(constants.fetchCredits, "POST", body);
    return response;
}
export function submitCarbonCredit(spreadArray) {
    const sheetDetails = {};
    spreadArray.forEach((spread, index) => {
        const sheet = spread.getSheet(0);
        const name = sheet.name();
        const number = "sheet" + (index + 1);
        const dataPoint = number + "_datapoint";
        const dataPointName = number + "_datapoint_name";

        for (let index = 0; index < sheet.getRowCount(); index++) {
            for (let index1 = 0; index1 < sheet.getColumnCount(); index1++) {
                if (
                    sheet.getValue(index, index1) === "" ||
                    sheet.getValue(index, index1) === null
                ) {
                    sheet.setValue(index, index1, 0);
                }
            }
        }

        const datapoint = sheet.getValue(1, 0, GC.Spread.Sheets.SheetArea.colHeader);
        sheetDetails[number] = sheet.getDataSource();
        sheetDetails[dataPoint] = datapoint;
        sheetDetails[dataPointName] = name;
    });
    return sheetDetails;
}
