import React, { useEffect, useState } from "react";
import "../App.css";
import api from "../Services/Api";
import submitupdates from "../Services/SubmitUpdates";
import { Button, Modal } from "react-bootstrap";
import * as constants from "../constants";
import { submitCarbonCredit } from "../Services/CarbonCredit";
import { submitUcomm } from "../Services/Ucomm";

export const Footer = (props) => {
    const { baseapi } = api();
    const { submit } = submitupdates();

    const [showModal, setShowModal] = useState(false);
    const [modalHeading, setModalHeading] = useState("");
    const [modalDescription, setModalDescription] = useState([]);

    const [enableSubmitUpdates, setenableSubmitUpdates] = useState(true);
    const [buttonVisibility, setbuttonVisibility] = useState("inline");

    const [count, setCount] = useState(0);
    const [timerValue, setTimerValue] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [elapsed, setElapsed] = useState();
    const [isTimerActive, setisTimerActive] = useState(false);
    const [pricingStatus, setPricingStatus] = useState("");
    const [pricingBy, setPricingBy] = useState("");
    const [pricingWhen, setPricingTime] = useState("");
    const [pricingCenter, setPricingCenter] = useState("");

    useEffect(() => {
        async function fetchData() {
            if (props.flag) {
                if (props.eodSheet === constants.sheetNames.PAPEREVENINGNUMBERS) {
                    setbuttonVisibility("none");
                } else {
                    setbuttonVisibility("inline");
                }
                const body = {
                    eod_sheet: props.eodSheet,
                };
                const resp = await baseapi(constants.getPublicationCutoff, "POST", body);
                if (resp.status_code === 200) {
                    setTimerValue(resp.publicationcutoff);
                    setElapsed(resp.hasTimeElasped);
                    setCount(1);
                    setisTimerActive(true);
                }
                const priceInfoBody = {
                    input_date: props.date,
                    eodsheet: props.eodSheet,
                };
                const priceInfoResp = await baseapi(
                    constants.priceSubmissionStatus,
                    "POST",
                    priceInfoBody
                );
                if (priceInfoResp.status_code === 200) {
                    setPricingStatus(priceInfoResp.status);
                    setPricingTime(priceInfoResp.when);
                    setPricingBy(priceInfoResp.by);
                }
            } else {
                setCount(0);
                setTimerValue(0);
                setHours(0);
                setMinutes(0);
                setElapsed(false);
                setisTimerActive(false);
                setPricingStatus("");
                setPricingTime("");
                setPricingBy("");
            }
        }
        fetchData();
    }, [props.flag, enableSubmitUpdates]);

    useEffect(() => {
        const isCenter = constants.pricingInfoRedStatus.indexOf(pricingStatus) !== -1;

        setPricingCenter(isCenter);
    }, [pricingStatus]);

    useEffect(() => {
        if (count === 1) {
            setHours(Math.floor(timerValue / 60));
            setMinutes(Math.floor(timerValue % 60));
        }
    }, [count, enableSubmitUpdates]);

    const handleModal = () => {
        setShowModal(false);
        setenableSubmitUpdates(true);
        setModalDescription([]);
    };

    const getData = async () => {
        props.disablePremiumTextbox(true);
        props.onClickSendEOD();

        let dataArr;
        if (props.eodSheet === constants.sheetNames.EODUSMETHANOL) {
            dataArr = submit(props.spreadArray, "EodUsMethanolUpdate");
        } else if (
            props.eodSheet === constants.sheetNames.EUCARBONCREDIT ||
            props.eodSheet === constants.sheetNames.USCARBONCREDIT
        ) {
            dataArr = submitCarbonCredit(props.spreadArray);
        } else if (props.eodSheet === constants.sheetNames.UCOMM) {
            dataArr = submitUcomm(props.spreadArray);
        } else {
            dataArr = submit(props.spreadArray, "submit updates");
        }
        let body = {};

        if (props.eodSheet === constants.sheetNames.PHYSICALEVENINGNUMBERS) {
            body = {
                data: dataArr,
                date: props.date,
                EODSheet: props.eodSheet,
                non_red_prem: props.nonRedPrem,
            };
        } else {
            body = {
                data: dataArr,
                date: props.date,
                EODSheet: props.eodSheet,
            };
        }

        try {
            setenableSubmitUpdates(false);
            props.showLoader(true);
            const resp = await baseapi(constants.sendEodDataToSql, "POST", body);

            if (resp.status_code === 200) {
                setModalHeading(resp.message);

                if (resp.warning === "") {
                    modalDescription.push("");
                } else {
                    const warnings = resp.warning.split("Warning");
                    for (let index = 0; index < warnings.length; index++) {
                        if (index === 0) {
                            modalDescription.push(warnings[index]);
                        } else {
                            modalDescription.push("Warning" + warnings[index]);
                        }
                    }
                }

                setShowModal(true);

                props.showLoader(false);
                props.onComplete(true);
                props.disablePremiumTextbox(false);
                const eodArchive = resp.eod_archive.eod_archive;
                const dataArr = {
                    eod_archive: eodArchive,
                };
                const body = {
                    data: dataArr,
                    date: props.date,
                    EODSheet: props.eodSheet,
                };

                const apiResponse = await baseapi(constants.sendEODArchive, "POST", body);
                if (apiResponse.status_code === 200) {
                    //
                }
            } else {
                setModalHeading(resp.error_msg);
                setenableSubmitUpdates(false);
                props.showLoader(false);
                setShowModal(true);
            }
        } catch (err) {
            // Handle Error Here
            setModalHeading(err.response.data.error_msg);
            setenableSubmitUpdates(false);
            setShowModal(true);
            props.showLoader(false);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="global-footer">
                    {props.flag ? (
                        <div>
                            <button
                                className="btn  row col-2 btn-footer"
                                style={{
                                    float: "left",
                                    display: buttonVisibility,
                                }}
                                onClick={getData}
                                disabled={!enableSubmitUpdates}
                            >
                                Submit Updates
                            </button>
                            <div className="float-left">
                                {elapsed && enableSubmitUpdates ? (
                                    <h6
                                        style={{
                                            marginLeft: "29px",
                                            float: "left",
                                            marginTop: "-5px",
                                        }}
                                        className="text-color confidential-text "
                                    >
                                        &nbsp; &nbsp; Estimated Time to publication cut-off:{" "}
                                        <span style={{ color: "red" }}>
                                            - {hours}h:{minutes}m{" "}
                                        </span>{" "}
                                        <br></br>
                                        <h6
                                            style={{
                                                textAlign: "center",
                                                color: "red",
                                            }}
                                        >
                                            (Updates will issue a correction)
                                        </h6>
                                    </h6>
                                ) : (
                                    <> </>
                                )}
                                {!elapsed && enableSubmitUpdates ? (
                                    <h6
                                        style={{
                                            marginLeft: "29px",
                                            float: "left",
                                            marginTop: "-5px",
                                        }}
                                        className="text-color confidential-text "
                                    >
                                        Estimated Time to publication cut-off: {hours}h:{minutes}m{" "}
                                    </h6>
                                ) : (
                                    <> </>
                                )}
                            </div>

                            {enableSubmitUpdates ? (
                                <>
                                    <div className="float-left"></div>
                                    <div className="pricing-div">
                                        <div id="verticle-line"></div>
                                        <div></div>
                                        <table className="margin-top64px">
                                            <tr>
                                                <td>
                                                    <div>
                                                        <h6
                                                            className={
                                                                !pricingCenter
                                                                    ? "text-color pricing-text margin-top25px"
                                                                    : "text-color pricing-text margin-top6"
                                                            }
                                                        >
                                                            Status:&nbsp;
                                                        </h6>{" "}
                                                        <h6
                                                            className={
                                                                !pricingCenter
                                                                    ? "text-color float-left margin-top25px"
                                                                    : "text-color-red float-left margin-top6"
                                                            }
                                                        >
                                                            {pricingStatus}
                                                        </h6>
                                                    </div>
                                                </td>
                                            </tr>

                                            {!pricingCenter ? (
                                                <>
                                                    <tr>
                                                        <td>
                                                            <h6 className="text-color pricing-text ">
                                                                By: {pricingBy}
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <h6 className="text-color pricing-text ">
                                                                When: {pricingWhen}{" "}
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <> </>
                                            )}
                                        </table>
                                    </div>
                                </>
                            ) : (
                                <> </>
                            )}

                            <div className="float-right">
                                <div id="verticle-line" className="float-left"></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#15bd78",
                                        marginLeft: "15px",
                                        marginTop: "-10px",
                                    }}
                                >
                                    <div className="text-color confidential-text ">
                                        Confidential
                                    </div>
                                    <div className="text-color confidential-text">
                                        Product of SCB Technology
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <> </>
                    )}

                    <Modal
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showModal}
                        onHide={handleModal}
                        backdrop="static"
                    >
                        <Modal.Header style={{ fontWeight: "bold", fontSize: "large" }}>
                            {modalHeading}
                        </Modal.Header>

                        <Modal.Body>
                            {modalDescription.map((item) => (
                                <p key={item}>
                                    {item}
                                    {"\n"}
                                </p>
                            ))}
                        </Modal.Body>

                        <Modal.Footer>
                            <Button
                                className="btn-success"
                                style={{ alignContent: "center" }}
                                onClick={handleModal}
                            >
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    );
};
