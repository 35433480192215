import logo from "../images/scb-logo.png";
export const Header = (props) => {
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <div className="container container-header" id="headerContainer">
                <div className="header contact-header">
                    <div className="row">
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <a href={`/`}>
                                <img src={logo} />
                            </a>
                        </div>
                        <div
                            className="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 header-text"
                            style={{ textAlign: "center" }}
                        >
                            Market Data Pricing Portal
                        </div>

                        <div
                            className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 right-menu"
                            style={{
                                paddingLeft: "20px",
                                width: "25%",
                                padding: "0",
                            }}
                        >
                            <button
                                className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 "
                                style={{
                                    float: "left",
                                    marginTop: "19px",
                                    paddingLeft: "5px",
                                    border: "2px solid blue",
                                    color: "blue",
                                    fontSize: "13px",
                                    height: "40px",
                                }}
                                onClick={() =>
                                    openInNewTab(
                                        "https://scbgroup.atlassian.net/wiki/spaces/SDMD1/pages/260309018/SCB+Market+Data+Web+User+Guide?atlOrigin=eyJpIjoiZGIzNGUxMzhlMTM5NGU5M2IxODM4OGE0ZTNiNDJhNTMiLCJwIjoiYyJ9"
                                    )
                                }
                            >
                                <b>View User Guide</b>
                            </button>
                            <div
                                id="userEmail"
                                className="col-xs-5  col-sm-5 col-md-5 col-lg-5 col-xl-5"
                                style={{
                                    float: "right",
                                    marginTop: "28px",
                                }}
                            >
                                {props.email}
                            </div>
                            <button
                                type="button"
                                id="deskList"
                                className="btn desk"
                                style={{
                                    backgroundColor: "#224C98",
                                    marginTop: "19px",
                                    float: "right",
                                    marginRight: "10px",
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 172 172"
                                    style={{ fill: "#000000" }}
                                >
                                    <g
                                        fill="none"
                                        fillRule="nonzero"
                                        stroke="none"
                                        strokeWidth={1}
                                        strokeLinecap="butt"
                                        strokeLinejoin="miter"
                                        strokeMiterlimit={10}
                                        strokeDashoffset={0}
                                        fontFamily="none"
                                        fontWeight="none"
                                        fontSize="none"
                                        textAnchor="none"
                                        style={{ mixBlendMode: "normal" }}
                                    >
                                        <path d="M0,172v-172h172v172z" fill="none" />
                                        <g fill="#ffffff">
                                            <path d="M86,21.5c-15.74728,0 -28.66667,12.91939 -28.66667,28.66667c0,15.74728 12.91939,28.66667 28.66667,28.66667c15.74727,0 28.66667,-12.91939 28.66667,-28.66667c0,-15.74728 -12.91939,-28.66667 -28.66667,-28.66667zM86,35.83333c8.00097,0 14.33333,6.33237 14.33333,14.33333c0,8.00097 -6.33237,14.33333 -14.33333,14.33333c-8.00097,0 -14.33333,-6.33237 -14.33333,-14.33333c0,-8.00097 6.33237,-14.33333 14.33333,-14.33333zM86,100.33333c-12.5055,0 -27.32533,2.93678 -39.7806,7.85254c-6.22764,2.45788 -11.85741,5.37749 -16.36296,9.12631c-4.50554,3.74881 -8.35645,8.81674 -8.35645,15.27116v17.91667h129v-7.16667v-10.75c0,-6.45441 -3.8509,-11.52234 -8.35645,-15.27116c-4.50554,-3.74882 -10.13531,-6.66843 -16.36295,-9.12631c-12.45527,-4.91576 -27.2751,-7.85254 -39.7806,-7.85254zM86,114.66667c10.134,0 23.98006,2.68033 34.53157,6.84473c5.27576,2.0822 9.73665,4.56436 12.44369,6.81673c2.70702,2.25238 3.1914,3.78662 3.1914,4.25521v3.58333h-100.33333v-3.58333c0,-0.46859 0.48437,-2.00283 3.19141,-4.25521c2.70703,-2.25238 7.16793,-4.73453 12.44368,-6.81673c10.55152,-4.1644 24.39757,-6.84473 34.53158,-6.84473z" />
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
