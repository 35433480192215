import React from "react";
import logo from "../pricing/images/scb-logo.png";
import "./Layout.css";
export default function Layout() {
    const details = [
        {
            button: "Brokerage Pricing",
            description:
                "For providing real-time prices of Biofuels, Ethanol, UCOME, UCO, German blending quota, RINs, LCFS, Black Sea Wheat, Black Sea Corn, Brazilian Soybean, Rice, Methanol, Lithium, Cobalt and many more. Prices are updated daily.",
            link: "/eodpricing",
        },
        {
            button: "REC Pricing",
            description: "For providing prices for Renewable energy certificates (RECs) prices.",
            link: "/recpricing",
        },

        {
            button: "I-REC Pricing",
            description: "For providing prices for the International REC (I-RECs) prices.",
            link: "/irecpricing",
        },
        {
            button: "Carbon Renewables Pricing",
            description: "For providing prices for the Carbon Renewables prices.",
            link: "/renewablespricing",
        },
        {
            button: "Carbon Forestry Pricing",
            description: "For providing prices for the Carbon Forestry prices.",
            link: "/forestrypricing",
        },
    ];
    return (
        <>
            <div className="container header" id="headTitle">
                <div className="row">
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 left-menu">
                        <img className="responsive-image" src={logo} />
                    </div>
                    <div
                        className="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 header-text"
                        id="headRow"
                    >
                        Market Data Pricing Platform
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 right-menu"></div>
                </div>
                <div className="row">
                    {details.map((item) => {
                        return (
                            <div className="jumbotron custom" key={item.button}>
                                <h4 className="title">{item.button}</h4>
                                <p>{item.description}</p>
                                <a className="btn btn-primary  link" href={`${item.link}`}>
                                    Go To {item.button}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
