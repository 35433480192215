import GC from "@grapecity/spread-sheets";

export const SubmitUpdates = () => {
    function fillSheet(index, sheet) {
        for (let index1 = 0; index1 < sheet.getColumnCount(); index1++) {
            if (sheet.getValue(index, index1) === "" || sheet.getValue(index, index1) === null) {
                sheet.setValue(index, index1, 0);
            }
        }
    }

    const submit = (spreadArray, event) => {
        const dataArr = {};
        if (event === "submit updates") {
            spreadArray.forEach((spread, index) => {
                const sheet = spread.getSheet(0);
                const name = sheet.name();
                const number = "sheet" + (index + 1);
                const bodyDatapoint = number + "_datapoint";
                const bodyDatapointName = number + "_datapoint_name";

                for (let index = 0; index < sheet.getRowCount(); index++) {
                    fillSheet(index, sheet);
                }

                const datapoint = sheet.getValue(1, 1, GC.Spread.Sheets.SheetArea.colHeader);
                dataArr[number] = sheet.getDataSource();
                dataArr[bodyDatapoint] = datapoint;
                dataArr[bodyDatapointName] = name;
            });
        } else if (event === "send Eod") {
            for (let index = 0; index < 6; index++) {
                const sheet = spreadArray[index].getSheet(0);
                const name = sheet.name();
                const number = "sheet" + (index + 1);
                const bodyDatapoint = number + "_datapoint";
                const bodyDatapointName = number + "_datapoint_name";

                for (let index = 0; index < sheet.getRowCount(); index++) {
                    fillSheet(index, sheet);
                }

                const datapoint = sheet.getValue(1, 1, GC.Spread.Sheets.SheetArea.colHeader);
                dataArr[number] = sheet.getDataSource();
                dataArr[bodyDatapoint] = datapoint;
                dataArr[bodyDatapointName] = name;
            }
        } else if (event === "send remaining eod") {
            for (let index = 6; index < 12; index++) {
                const sheet = spreadArray[index].getSheet(0);
                const name = sheet.name();
                const number = "sheet" + (index - 5);
                const bodyDatapoint = number + "_datapoint";
                const bodyDatapointName = number + "_datapoint_name";

                for (let index = 0; index < sheet.getRowCount(); index++) {
                    fillSheet(index, sheet);
                }

                const datapoint = sheet.getValue(1, 1, GC.Spread.Sheets.SheetArea.colHeader);
                dataArr[number] = sheet.getDataSource();
                dataArr[bodyDatapoint] = datapoint;
                dataArr[bodyDatapointName] = name;
            }
        } else if (event === "EodUsMethanolUpdate") {
            spreadArray.forEach((spread, index) => {
                if (index === 0) {
                    const sheet = spread.getSheet(0);
                    const name = sheet.name();
                    const number = "sheet" + (index + 1);
                    const bodyDatapoint = number + "_datapoint";
                    const bodyDatapointName = number + "_datapoint_name";
                    for (let index = 0; index < sheet.getRowCount(); index++) {
                        fillSheet(index, sheet);
                    }

                    const datapoint = sheet.getValue(1, 1, GC.Spread.Sheets.SheetArea.colHeader);
                    dataArr[number] = sheet.getDataSource();
                    dataArr[bodyDatapoint] = datapoint;
                    dataArr[bodyDatapointName] = name;
                } else if (index === 4) {
                    const sheet = spread.getSheet(0);
                    const name = sheet.name();
                    const number = "sheet" + (index - 2);
                    const bodyDatapoint = number + "_datapoint";
                    const bodyDatapointName = number + "_datapoint_name";
                    for (let index = 0; index < sheet.getRowCount(); index++) {
                        fillSheet(index, sheet);
                    }

                    const datapoint = sheet.getValue(1, 1, GC.Spread.Sheets.SheetArea.colHeader);
                    dataArr[number] = sheet.getDataSource();
                    dataArr[bodyDatapoint] = datapoint;
                    dataArr[bodyDatapointName] = name;
                }
            });
        }
        return dataArr;
    };
    return { submit };
};

export default SubmitUpdates;
