import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import validate from "../Utils/validationInfo";
import api from "../Services/Api";
import { Spreadsheets } from "./Spreadsheets";
import submitupdates from "../Services/SubmitUpdates";
import { Button, Modal } from "react-bootstrap";
import * as constants from "../constants";
import CarbonCreditForm from "./CarbonCreditForm";
import { CarbonCredit } from "../Services/CarbonCredit";
import { Ucomm } from "../Services/Ucomm";

export const Controls = (props) => {
    const { baseapi } = api();
    const { submit } = submitupdates();

    const [values, setValues] = useState({
        EODFunction: "",
        PriceEntryDate: new Date(),
    });

    const [errors, setErrors] = useState({});
    const [updateTodayFLag, setupdateTodayFlag] = useState(true);
    const [updateHistoricalFLag, setupdateHistoricalFlag] = useState(true);
    const [loaderFlag, setLoaderFlag] = useState(true);
    const [errorFlag, setErrorFlag] = useState(false);
    const [midFormula, setMidFormula] = useState(false);
    const [physicalTextBox, setPhysicalTextBox] = useState(false);
    const [physicalEvengNumbers, setPhysicalEvengNumbers] = useState(false);
    const [UsMethanol, setUsMethanol] = useState(false);
    const [defaultEurUsd, setDefaultEurUsd] = useState("");
    const [defaultUsMethEUR, setDefaultUsMethEUR] = useState("");
    const [defaultTWT, setDefaultTWT] = useState("");

    const [spotMonthValue, setSpotMonthValue] = useState("");

    const [paperEveningButtons, setPaperEveningButtons] = useState(false);
    const [paperEveningBtn1, setpaperEveningBtn1] = useState(false);
    const [paperEveningBtn2, setpaperEveningBtn2] = useState(true);
    const [paperEveningBtn3, setpaperEveningBtn3] = useState(true);
    const [GasOilRefId, setGasOilRefId] = useState("physicalEvengGasOilRef");
    const [paperEveningFlag, setpaperEveningFlag] = useState(true);

    const [focusOutFlag, setFocusOutFlag] = useState(false);
    const [doubleFocusFlag, setDoubleFocusFlag] = useState(false);

    const [physicalTextBoxValue, setphysicalTextBoxValue] = useState("");
    const [nonRedPremTextBoxValue, setNonRedPremTextBoxValue] = useState("");
    const [EurUsDValue, setEurUsDValue] = useState("");
    const [WarningMessage, setWarningMessage] = useState("");

    const [twtgalValue, setTwtgalValue] = useState("");

    const [addPremiumCLicked, setaddPremiumCLicked] = useState(false);
    const [nonRedPremiumUpdate, setNonRedPremiumUpdate] = useState(false);

    const [fxRateTextBoxValue, setFxRateTextBoxValue] = useState("");
    const [gasOilTextBoxValue, setGasOilTextBoxValue] = useState("");
    const [fxRateUpdate, setFxRateUpdate] = useState(false);
    const [gasOilUpdate, setGasOilUpdate] = useState(false);
    const [showFxRateModal, setShowFxRateModal] = useState(false);
    const [showInvalidGasoilMessage, setShowInvalidGasoilMessage] = useState("");
    const [showInvalidGasoil, setShowInvalidGasoil] = useState(false);

    const [disablePremium, setDisablePremium] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [modalHeading, setModalHeading] = useState("");
    const [modalDescription, setModalDescription] = useState([]);
    const [eodCarbonCredit, seteodCarbonCredit] = useState(false);
    const [fetchCarbonCredit, setFetchCarbonCredit] = useState(false);
    const [ucomm, setUcomm] = useState(false);
    const [ucommFormula, setUcommFormula] = useState(false);
    const ucomBtn = true;

    const [fetchUcomm, setFetchUcomm] = useState(false);

    const [showNewTicker, setShowNewTicker] = useState(false);
    const current = new Date();
    const [eodSheetData, setEodSheetData] = useState([]);
    const [eodSheetCount, setEodSheetCount] = useState(0);
    const [eodSheetDataLength, setEodSheetDataLength] = useState(false);
    const [nonRedPremFlag, setNonRedPremFlag] = useState(false);
    const [fxRateFlag, setFxRateFlag] = useState(false);
    const [gasOilFlag, setGasOilFlag] = useState(false);
    const [eurUsdFlag, seteurUsdFlag] = useState(false);
    const [twtgalFlag, setTwtGalFlag] = useState(false);
    const [productGroupNameList, setProductGroupNameList] = useState([]);
    const [fetchUcommInputDate, setFetchUcommInputDate] = useState("");

    function invalidGasOil(props, response) {
        if (!props.sendEODbutton) {
            setShowInvalidGasoil(true);
            setShowInvalidGasoilMessage(response.message);
        }
    }
    function setGasOilState(response, props) {
        if (response.message !== "") {
            invalidGasOil(props, response);
            props.onClickUpdate();
        } else {
            setGasOilRefId("physicalEvengGasOilRef");
        }
    }
    const apiCall = async (body) => {
        try {
            setLoaderFlag(false);
            const response = await baseapi(constants.checkForRollOver, "POST", body);
            if (response.status_code === 200) {
                setEodSheetData(response.data);
                setEodSheetCount(response.count);
                setEodSheetDataLength(true);
                setLoaderFlag(true);

                if (values.EODFunction === constants.sheetNames.PHYSICALEVENINGNUMBERS) {
                    setNonRedPremTextBoxValue(response.non_red_prem);
                    setFxRateTextBoxValue(response.fxrate);
                    setGasOilTextBoxValue(response.gasoil);
                    props.settingNonRedPrem(response.non_red_prem);
                    setDefaultEurUsd(response.fxrate);

                    const dateArray = values.PriceEntryDate.toString();
                    const dateArr = dateArray.split(" ");
                    const val = dateArr[1] + "/" + dateArr[3].slice(-2);
                    setSpotMonthValue(val);

                    setGasOilState(response, props);
                } else if (values.EODFunction === constants.sheetNames.EODUSMETHANOL) {
                    setEurUsDValue(response.fxrate);
                    setTwtgalValue(response.gal_convt);
                    setWarningMessage(response.updated_number_msg);

                    setDefaultUsMethEUR(response.fxrate);
                    setDefaultTWT(response.gal_convt);
                }
            }
        } catch (err) {
            setErrorFlag(true);
            setLoaderFlag(true);
        }
    };
    const getFormattedDate = (date) => {
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : "0" + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : "0" + day;

        return year + "-" + month + "-" + day;
    };
    const handleChange = (event) => {
        setFetchCarbonCredit(false);
        seteodCarbonCredit(false);
        setUcomm(false);
        setFetchUcomm(false);

        setShowNewTicker(false);
        const { value } = event.target;

        setValues({
            ...values,
            [event.target.name]: value,
        });

        if (event.target.name === "EODFunction") {
            setErrors({
                PriceEntryDate: errors.PriceEntryDate,
                EODFunction: "",
            });
            values.EODFunction = value;
        } else if (event.target.name === "PriceEntryDate") {
            if (event.target.value.toDateString() !== current.toDateString()) {
                setErrors({
                    PriceEntryDate: "",
                    EODFunction: errors.EODFunction,
                });
            }
        }

        if (
            values.EODFunction === constants.sheetNames.EUCARBONCREDIT ||
            values.EODFunction === constants.sheetNames.USCARBONCREDIT
        ) {
            seteodCarbonCredit(true);
        } else if (values.EODFunction === constants.sheetNames.UCOMM) {
            setUcomm(true);
        }

        if (values.EODFunction === constants.sheetNames.EUETHANOLEVENINGNUMBERS) {
            setPhysicalTextBox(true);
            setPaperEveningButtons(false);
            setPhysicalEvengNumbers(false);
            setUsMethanol(false);
        } else if (values.EODFunction === constants.sheetNames.PAPEREVENINGNUMBERS) {
            setPaperEveningButtons(true);
            setPhysicalTextBox(false);
            setPhysicalEvengNumbers(false);
            setUsMethanol(false);
        } else if (values.EODFunction === constants.sheetNames.PHYSICALEVENINGNUMBERS) {
            setPhysicalEvengNumbers(true);
            setPaperEveningButtons(false);
            setPhysicalTextBox(false);
            setUsMethanol(false);
        } else if (values.EODFunction === constants.sheetNames.EODUSMETHANOL) {
            setPhysicalEvengNumbers(false);
            setPaperEveningButtons(false);
            setPhysicalTextBox(false);
            setUsMethanol(true);
        } else {
            setPhysicalTextBox(false);
            setPaperEveningButtons(false);
            setPhysicalEvengNumbers(false);
            setUsMethanol(false);
        }

        setEodSheetDataLength(false);
        setupdateHistoricalFlag(true);
        setupdateTodayFlag(true);
        setpaperEveningBtn1(false);
        setpaperEveningBtn2(true);
        setpaperEveningBtn3(true);
        setphysicalTextBoxValue("");
        setNonRedPremTextBoxValue("");
        setFxRateTextBoxValue("");
        setGasOilTextBoxValue("");

        props.onLoad(
            false,
            [],
            getFormattedDate(values.PriceEntryDate),
            values.EODFunction,
            "",
            "",
            ""
        );
        setErrorFlag(false);
        props.cutOff();
    };
    const fetchCarbonCreditData = async () => {
        try {
            setLoaderFlag(false);
            setEodSheetDataLength(false);
            const body = {
                date: getFormattedDate(values.PriceEntryDate),
                eod_sheet: values.EODFunction,
            };

            const response = await CarbonCredit(baseapi, body);
            if (response.status_code === 200) {
                setProductGroupNameList([]);
                setEodSheetData(response.data);
                setEodSheetCount(response.count);
                setEodSheetDataLength(true);
                setFetchCarbonCredit(true);
                setProductGroupNameList(response.drop_list);
                setLoaderFlag(true);
            }
        } catch (err) {
            setErrorFlag(true);
            setLoaderFlag(true);
        }
    };
    const fetchUcommData = async () => {
        try {
            setLoaderFlag(false);
            setEodSheetDataLength(false);
            const body = {
                date: getFormattedDate(values.PriceEntryDate),
                eod_sheet: values.EODFunction,
            };
            const response = await Ucomm(baseapi, body);
            if (response.status_code === 200) {
                setEodSheetData(response.data);
                setEodSheetCount(response.count);
                setFetchUcommInputDate(response.input_date);
                setFetchUcomm(true);
                setEodSheetDataLength(true);
                setLoaderFlag(true);
            }
        } catch (err) {
            setErrorFlag(true);
            setLoaderFlag(true);
        }
    };
    const onComplete = () => {
        setEodSheetDataLength(false);

        const body = {
            date: getFormattedDate(values.PriceEntryDate),
            eod_sheet: values.EODFunction,
            phy_premium: physicalTextBoxValue,
            paper_evening_numbers: [],
        };

        eodCarbonCredit ? fetchCarbonCreditData() : ucomm ? fetchUcommData() : apiCall(body);
    };

    useEffect(() => {
        if (props.premiumTextbox) {
            setphysicalTextBoxValue("");
            setDisablePremium(true);
        }
    }, [props.premiumTextbox]);

    const handleUserInput = (event) => {
        setphysicalTextBoxValue(event.target.value);
        setDisablePremium(!disablePremium);

        if (event.target.value === "" || event.target.value === 0) {
            setDisablePremium(true);
            setFocusOutFlag(false);
        } else {
            setDisablePremium(false);
            setFocusOutFlag(true);
        }
    };

    const handleUserInputPremium = (event) => {
        setNonRedPremTextBoxValue(event.target.value);
        props.settingNonRedPrem(nonRedPremTextBoxValue);
        setNonRedPremFlag(true);
    };
    const handleUserInputFxRate = (event) => {
        setFxRateTextBoxValue(event.target.value);
        setFxRateFlag(true);
    };
    const handleUserInputGasOil = (event) => {
        setGasOilTextBoxValue(event.target.value);
        setGasOilFlag(true);
    };
    const handleEurUsDValue = (event) => {
        setEurUsDValue(event.target.value);
    };
    const handletwtgal = (event) => {
        setTwtgalValue(event.target.value);
    };

    useEffect(() => {
        if (props.onCompleteFlag) {
            onComplete();
            props.onComplete(false);
        }

        // eslint-disable-next-line
    }, [props.onCompleteFlag]);

    const handleModal = () => {
        setShowModal(false);
        setModalDescription([]);
    };

    const handleNullFxRate = () => {
        setShowFxRateModal(false);
    };

    const handleInvalidGasoil = () => {
        setShowInvalidGasoil(false);
        setGasOilRefId("physicalEvengGasOilRefError");
    };

    const UpdateTodayPrice = (event) => {
        event.preventDefault();

        setupdateHistoricalFlag(true);
        setEodSheetDataLength(false);
        const UpdateType = "Today";
        eodCarbonCredit ? setShowNewTicker(true) : setShowNewTicker(false);
        setErrors(validate(values, UpdateType));

        if (values.EODFunction !== "") {
            setupdateTodayFlag(false);
            values.PriceEntryDate = new Date();

            const body = {
                date: getFormattedDate(values.PriceEntryDate),
                eod_sheet: values.EODFunction,
                phy_premium: physicalTextBoxValue,
                paper_evening_numbers: [],
            };
            setMidFormula(true);
            eodCarbonCredit ? fetchCarbonCreditData() : ucomm ? fetchUcommData() : apiCall(body);
            props.onLoad(
                false,
                [],
                getFormattedDate(values.PriceEntryDate),
                values.EODFunction,
                "",
                "",
                ""
            );
        }
    };

    const UpdateHistoricalPrice = (event) => {
        event.preventDefault();
        setShowNewTicker(false);
        const UpdateType = "Historical";

        setErrors(validate(values, UpdateType));
        if (
            values.EODFunction !== "" &&
            getFormattedDate(values.PriceEntryDate) < getFormattedDate(new Date())
        ) {
            setupdateHistoricalFlag(false);

            const body = {
                date: getFormattedDate(values.PriceEntryDate),
                eod_sheet: values.EODFunction,
                phy_premium: physicalTextBoxValue,
                paper_evening_numbers: [],
            };

            setMidFormula(false);
            eodCarbonCredit ? fetchCarbonCreditData() : ucomm ? fetchUcommData() : apiCall(body);
        }
    };
    const changepreiumclicked = (event) => {
        setaddPremiumCLicked(false);
    };
    const addPremium = (event) => {
        event.preventDefault();
        if (!doubleFocusFlag) {
            setaddPremiumCLicked(true);
        }
        setDoubleFocusFlag(false);
    };

    const addNonRedPremium = (event) => {
        event.preventDefault();
        setNonRedPremiumUpdate(true);
        props.settingNonRedPrem(nonRedPremTextBoxValue);
    };

    const changeNonRedPremiumUpdate = (event) => {
        setNonRedPremiumUpdate(false);
    };

    const addFxRate = (event) => {
        event.preventDefault();

        const val = fxRateTextBoxValue;
        if (parseInt(val) === 0 || val === "" || val === null || parseFloat(val) < 0) {
            setShowFxRateModal(true);
            setFxRateTextBoxValue(defaultEurUsd);
        }
        setFxRateUpdate(true);
    };
    const updateEurUsDValue = (event) => {
        event.preventDefault();
        const val = EurUsDValue;
        if (parseInt(val) === 0 || val === "" || val === null || parseFloat(val) < 0) {
            setShowFxRateModal(true);
            setEurUsDValue(defaultUsMethEUR);
        }
        seteurUsdFlag(true);
    };
    const updatetwtgal = (event) => {
        event.preventDefault();
        const val = twtgalValue;
        if (parseInt(val) === 0 || val === "" || val === null || parseFloat(val) < 0) {
            setShowFxRateModal(true);
            setTwtgalValue(defaultTWT);
        }
        setTwtGalFlag(true);
    };

    const disableUcombtn = () => {
        setUcommFormula(false);
    };
    const disableEurUsdFlag = () => {
        seteurUsdFlag(false);
    };
    const disableTwtGalFlag = () => {
        setTwtGalFlag(false);
    };

    const changeFxRateUpdate = (event) => {
        setFxRateUpdate(false);
    };

    const addGasOil = (event) => {
        event.preventDefault();
        setGasOilUpdate(true);
    };

    const changeGasOilUpdate = (event) => {
        setGasOilUpdate(false);
    };

    const focusOutPremium = (event) => {
        event.preventDefault();
        if (focusOutFlag) {
            setaddPremiumCLicked(true);
            setDoubleFocusFlag(true);
        }
        setFocusOutFlag(false);
    };
    function fillModalDescription(warnings) {
        for (let index = 0; index < warnings.length; index++) {
            if (index === 0) {
                modalDescription.push(warnings[index]);
            } else {
                modalDescription.push("Warning" + warnings[index]);
            }
        }
    }
    const sendEODToSql = async () => {
        const dataArr = submit(props.spreadArray, "send Eod");
        const body = {
            data: dataArr,
            date: props.date,
            EODSheet: props.eodSheet,
        };

        try {
            setpaperEveningFlag(false);
            setpaperEveningBtn1(true);
            const resp = await baseapi(constants.sendEodDataToSql, "POST", body);

            if (resp.status_code === 200) {
                setModalHeading(resp.message);

                if (resp.warning === "") {
                    modalDescription.push("");
                } else {
                    const warnings = resp.warning.split("Warning");
                    fillModalDescription(warnings);
                }

                setShowModal(true);

                setpaperEveningBtn1(false);
                setpaperEveningFlag(true);
                props.onComplete(true);
                setpaperEveningBtn2(false);
                const eodArchive = resp.eod_archive.eod_archive;
                const dataArr = {
                    eod_archive: eodArchive,
                };
                const body = {
                    data: dataArr,
                    date: props.date,
                    EODSheet: props.eodSheet,
                };

                const apiResponse = await baseapi(constants.sendEODArchive, "POST", body);
                if (apiResponse.status_code === 200) {
                    //
                }
            }
        } catch (err) {
            setModalHeading(err.response.data.error_msg);

            setShowModal(true);
            setpaperEveningFlag(false);
            props.onComplete(true);
        }
    };
    const ucomFormulaFunc = () => {
        setUcommFormula(true);
    };

    const getRefrences = async () => {
        const body = {
            date: getFormattedDate(values.PriceEntryDate),
            eod_sheet: values.EODFunction,
            phy_premium: physicalTextBoxValue,
            paper_evening_numbers: constants.paperEveningNumbers,
        };
        try {
            setpaperEveningFlag(false);
            setpaperEveningBtn1(true);
            setpaperEveningBtn2(true);
            setpaperEveningBtn3(true);

            const response = await baseapi(constants.checkForRollOver, "POST", body);
            if (response.status_code === 200) {
                setEodSheetData(response.data);
                setEodSheetCount(response.count);
                setEodSheetDataLength(true);
                setpaperEveningFlag(true);
                setpaperEveningBtn1(true);
                setpaperEveningBtn2(false);
                setpaperEveningBtn3(false);
            }
        } catch (err) {
            setErrorFlag(true);
            setpaperEveningFlag(true);
        }
    };

    const sendRemainingEODToSql = async () => {
        const dataArr = submit(props.spreadArray, "send remaining eod");

        const body = {
            data: dataArr,
            date: props.date,
            EODSheet: props.eodSheet,
        };
        try {
            setpaperEveningFlag(false);
            setpaperEveningBtn1(true);
            setpaperEveningBtn2(true);
            setpaperEveningBtn3(true);

            const resp = await baseapi(constants.sendEodDataToSql, "POST", body);

            if (resp.status_code === 200) {
                setModalHeading(resp.message);

                if (resp.warning === "") {
                    modalDescription.push("");
                } else {
                    const warnings = resp.warning.split("Warning");
                    fillModalDescription(warnings);
                }

                setShowModal(true);

                setpaperEveningBtn2(false);
                setpaperEveningBtn1(false);

                setpaperEveningFlag(true);
                props.onComplete(true);
                const eodArchive = resp.eod_archive.eod_archive;
                const dataArr = {
                    eod_archive: eodArchive,
                };
                const body = {
                    data: dataArr,
                    date: props.date,
                    EODSheet: props.eodSheet,
                };

                const apiResponse = await baseapi(constants.sendEODArchive, "POST", body);
                if (apiResponse.status_code === 200) {
                    //
                }
            }
        } catch (err) {
            setModalHeading(err.response.data.error_msg);

            setShowModal(true);
            setpaperEveningFlag(false);
            props.onComplete(true);
        }
    };

    return (
        <>
            <div
                className="container controls-container "
                style={{ marginTop: "-60px", width: "100%" }}
            >
                <div className="row">
                    <div id="filterContainer">
                        <div className="row additional">
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                <div className="row">
                                    <label className="labels" htmlFor="EOD">
                                        EOD Sheet
                                    </label>

                                    <div
                                        className="operations"
                                        style={{
                                            marginLeft: "-10px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <select
                                            className="form-control"
                                            style={{
                                                WebkitAppearance: "menulist",
                                                appearance: "auto",
                                                boxSizing: "border-box",
                                                paddingBottom: "5px",
                                            }}
                                            id="EODFunction"
                                            placeholder="Select EOD Sheet"
                                            name="EODFunction"
                                            value={values.EODFunction}
                                            onChange={handleChange}
                                        >
                                            <option
                                                id="option"
                                                value=""
                                                disabled
                                                hidden
                                                selected="selected"
                                            >
                                                Select EOD Sheet
                                            </option>
                                            {props.eodList.map((item) => {
                                                return (
                                                    <option
                                                        key={item.EODSHEET}
                                                        value={item.EODSHEET}
                                                    >
                                                        {item.EODSHEET}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="row">
                                        <span
                                            className="validation"
                                            id="eodError"
                                            style={{ color: "red" }}
                                        >
                                            {errors.EODFunction && (
                                                <p
                                                    style={{
                                                        position: "absolute",
                                                    }}
                                                >
                                                    {errors.EODFunction}
                                                </p>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3  col-md-3 col-lg-3 col-xl-3  updatePrice">
                                <button
                                    className="btn btn-submit"
                                    disabled={!updateTodayFLag}
                                    onClick={UpdateTodayPrice}
                                >
                                    Enter Today's Price
                                </button>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                <div className="row">
                                    <label className="labels" htmlFor="identifier">
                                        Price Entry Date
                                    </label>

                                    <div className="operations">
                                        <DatePicker
                                            value={getFormattedDate(values.PriceEntryDate)}
                                            onChange={(date) => {
                                                handleChange({
                                                    target: {
                                                        value: date,
                                                        name: "PriceEntryDate",
                                                    },
                                                });
                                            }}
                                            maxDate={new Date()}
                                            className="form-control"
                                            id="priceEntryDate"
                                            name="PriceEntryDate"
                                        />
                                    </div>
                                    <div>
                                        <span
                                            className="validation"
                                            id="dateError"
                                            style={{ color: "red" }}
                                        >
                                            {errors.PriceEntryDate && (
                                                <p
                                                    style={{
                                                        position: "absolute",
                                                    }}
                                                >
                                                    {errors.PriceEntryDate}
                                                </p>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3 col-md-3 col=lg-3 col-xl-3 updatePrice">
                                <button
                                    className="btn btn-submit"
                                    disabled={!updateHistoricalFLag}
                                    onClick={UpdateHistoricalPrice}
                                >
                                    Edit Historical Price
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {physicalTextBox && eodSheetDataLength ? (
                        <>
                            <div className="row">
                                <div className="col-sm-3 "></div>
                                <div className="col-sm-3 ">
                                    <label
                                        className="labels"
                                        htmlFor="EOD"
                                        style={{
                                            float: "right",
                                            marginTop: "30px",
                                        }}
                                    >
                                        Enter Physical Premium
                                    </label>
                                </div>

                                <div
                                    className="col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                    style={{ marginTop: "30px" }}
                                >
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="physicalPremium"
                                        value={physicalTextBoxValue}
                                        onChange={handleUserInput}
                                        onBlur={focusOutPremium}
                                    ></input>
                                </div>

                                <div className="col-sm-3  col-md-3 col-lg-3 col-xl-3 updatePrice">
                                    {eodSheetDataLength ? (
                                        <button
                                            id="premium_btn"
                                            className="btn btn-submit "
                                            style={{
                                                marginTop: "-10px !important",
                                            }}
                                            onClick={addPremium}
                                            disabled={disablePremium}
                                        >
                                            Add Premium
                                        </button>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <> </>
                    )}
                </div>

                <div>
                    {paperEveningButtons && eodSheetDataLength ? (
                        <>
                            <div
                                className="row"
                                style={{
                                    float: "right",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                }}
                            >
                                <div className="col-sm-3"></div>
                                <div className="col-sm-3">
                                    <button
                                        disabled={paperEveningBtn1}
                                        onClick={sendEODToSql}
                                        style={{
                                            backgroundColor: "#ff726f",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        1.Send Paper Numbers to Sql
                                    </button>
                                </div>
                                <div className="col-sm-3">
                                    <button
                                        disabled={paperEveningBtn2}
                                        onClick={getRefrences}
                                        style={{
                                            backgroundColor: "yellow",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        2.Get Reference Prices for Pme/Sme and Non Reds
                                    </button>
                                </div>
                                <div className="col-sm-3">
                                    <button
                                        disabled={paperEveningBtn3}
                                        onClick={sendRemainingEODToSql}
                                        style={{
                                            backgroundColor: "#70ad47",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        3. Send in Remaining Data To SQL
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <> </>
                    )}
                </div>
                <div>
                    {ucomm && eodSheetDataLength ? (
                        <>
                            <div
                                className="row"
                                style={{
                                    float: "right",
                                    width: "25.5%",
                                    marginBottom: "10px",
                                }}
                            >
                                <div className="updatePrice">
                                    <button
                                        className="btn btn-submit"
                                        onClick={ucomFormulaFunc}
                                        disabled={!ucomBtn}
                                        style={{ fontSize: "14px" }}
                                    >
                                        Add Formula To All Rows For EUBIOFUELUCOREDCIFARAPHYSICAL
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <> </>
                    )}
                </div>

                <div>
                    {physicalEvengNumbers && eodSheetDataLength ? (
                        <>
                            <div className="row" style={{ float: "right", marginBottom: "20px" }}>
                                <div className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-5 ">
                                            <label
                                                className="labels"
                                                htmlFor="EOD"
                                                style={{
                                                    float: "left",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                SPOTMONTH
                                            </label>
                                        </div>

                                        <div
                                            className="col-sm-5 col-md-5 col-lg-5 col-xl-5"
                                            style={{
                                                marginTop: "30px",
                                                float: "left",
                                            }}
                                        >
                                            <input
                                                className="form-control"
                                                id="physicalEvengSpotMonth"
                                                value={spotMonthValue}
                                            ></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-8 ">
                                            <label
                                                className="labels"
                                                htmlFor="EOD"
                                                style={{
                                                    float: "right",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                Non-Red Premium
                                            </label>
                                        </div>

                                        <div
                                            className="col-sm-4 col-md-4 col-lg-4 col-xl-4"
                                            style={{
                                                marginTop: "30px",
                                                float: "left",
                                            }}
                                        >
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="physicalEvengPremium"
                                                value={nonRedPremTextBoxValue}
                                                onChange={handleUserInputPremium}
                                                onBlur={addNonRedPremium}
                                            ></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-7 ">
                                            <label
                                                className="labels"
                                                htmlFor="EOD"
                                                style={{
                                                    float: "right",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                EUR/USD
                                            </label>
                                        </div>

                                        <div
                                            className="col-sm-5 col-md-5 col-lg-5 col-xl-5"
                                            style={{
                                                marginTop: "30px",
                                                float: "left",
                                            }}
                                        >
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="physicalEvengEUR_USD"
                                                value={fxRateTextBoxValue}
                                                onChange={handleUserInputFxRate}
                                                onBlur={addFxRate}
                                            ></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="row">
                                        <div className="col-sm-7 ">
                                            <label
                                                className="labels"
                                                htmlFor="EOD"
                                                style={{
                                                    float: "right",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                Gasoil Ref
                                            </label>
                                        </div>

                                        <div
                                            className="col-sm-5 col-md-5 col-lg-5 col-xl-5"
                                            style={{
                                                marginTop: "30px",
                                                float: "left",
                                            }}
                                        >
                                            <input
                                                type="number"
                                                className="form-control"
                                                id={GasOilRefId}
                                                value={gasOilTextBoxValue}
                                                onChange={handleUserInputGasOil}
                                                onBlur={addGasOil}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <> </>
                    )}
                </div>

                <div>
                    {UsMethanol && eodSheetDataLength ? (
                        <>
                            <div className="row">
                                <div className="col-sm-3">
                                    <textarea
                                        style={{
                                            marginTop: "5px",
                                            float: "left",
                                            height: "70px",
                                            backgroundColor: "RGB(255, 192, 0)",
                                            padding: "10px",
                                            fontSize: "13px",
                                            wordWrap: "break-word",
                                        }}
                                        type="text"
                                        className="form-control"
                                        id="warningBox"
                                        value={WarningMessage}
                                        readOnly
                                    ></textarea>
                                </div>

                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <label
                                                className="labels"
                                                htmlFor="EOD"
                                                style={{
                                                    float: "right",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                Convert EUR/mt - USD/mt
                                            </label>
                                        </div>

                                        <div
                                            className="col-sm-4 col-md-4 col-lg-4 col-xl-4"
                                            style={{
                                                marginTop: "30px",
                                                float: "left",
                                            }}
                                        >
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="physicalEvengPremium"
                                                value={EurUsDValue}
                                                onChange={handleEurUsDValue}
                                                onBlur={updateEurUsDValue}
                                            ></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <label
                                                className="labels"
                                                htmlFor="EOD"
                                                style={{
                                                    float: "right",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                Convert (¢/gal) - (twt/gal)
                                            </label>
                                        </div>

                                        <div
                                            className="col-sm-4 col-md-4 col-lg-4 col-xl-4"
                                            style={{
                                                marginTop: "30px",
                                                float: "left",
                                            }}
                                        >
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="physicalEvengEUR_USD"
                                                disabled="disabled"
                                                value={twtgalValue}
                                                onChange={handletwtgal}
                                                onBlur={updatetwtgal}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <> </>
                    )}
                </div>

                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showModal}
                    onHide={handleModal}
                    backdrop="static"
                >
                    <Modal.Header style={{ fontWeight: "bold", fontSize: "large" }}>
                        {modalHeading}
                    </Modal.Header>

                    <Modal.Body>
                        {modalDescription.map((item) => (
                            <p key={item}>
                                {item}
                                {"\n"}
                            </p>
                        ))}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="btn-success"
                            style={{ alignContent: "center" }}
                            onClick={handleModal}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showFxRateModal}
                    onHide={handleNullFxRate}
                    backdrop="static"
                >
                    <Modal.Header style={{ fontWeight: "bold", fontSize: "large" }}>
                        Warning!
                    </Modal.Header>

                    <Modal.Body>Please enter a valid value.</Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="btn-success"
                            style={{ alignContent: "center" }}
                            onClick={handleNullFxRate}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showInvalidGasoil}
                    onHide={handleInvalidGasoil}
                    backdrop="static"
                >
                    <Modal.Header style={{ fontWeight: "bold", fontSize: "large" }}>
                        Warning!
                    </Modal.Header>

                    <Modal.Body>{showInvalidGasoilMessage}</Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="btn-success"
                            style={{ alignContent: "center" }}
                            onClick={handleInvalidGasoil}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

                {showNewTicker ? (
                    <>
                        <CarbonCreditForm
                            loaderFlag={loaderFlag}
                            productGroupNameList={productGroupNameList}
                            fetchCarbonCreditData={fetchCarbonCreditData}
                            selectedDate={getFormattedDate(values.PriceEntryDate)}
                            sheetName={values.EODFunction}
                        />
                    </>
                ) : (
                    <></>
                )}
                <br />
                <div className="container">
                    <div
                        className="row col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        style={{ width: "100%", height: "100%", padding: "0" }}
                    >
                        <div className="content2" style={{ marginTop: "30px" }}>
                            {fetchCarbonCredit || fetchUcomm ? (
                                eodSheetDataLength && !props.showLoader && !errorFlag ? (
                                    <Spreadsheets
                                        eodSheetData={eodSheetData}
                                        eodSheetCount={eodSheetCount}
                                        eodSheetDate={getFormattedDate(values.PriceEntryDate)}
                                        eodSheetName={values.EODFunction}
                                        onLoad={props.onLoad}
                                        onComplete={onComplete}
                                        fetchUcommInputDate={fetchUcommInputDate}
                                        ucommFormula={ucommFormula}
                                        disableUcombtn={disableUcombtn}
                                    ></Spreadsheets>
                                ) : (
                                    <></>
                                )
                            ) : eodSheetDataLength &&
                              !props.showLoader &&
                              !errorFlag &&
                              paperEveningFlag ? (
                                <Spreadsheets
                                    eodSheetData={eodSheetData}
                                    eodSheetCount={eodSheetCount}
                                    eodSheetDate={getFormattedDate(values.PriceEntryDate)}
                                    eodSheetName={values.EODFunction}
                                    onComplete={onComplete}
                                    onLoad={props.onLoad}
                                    midFormula={midFormula}
                                    addPremiumCLicked={addPremiumCLicked}
                                    physicalTextBoxValue={physicalTextBoxValue}
                                    changepreiumclicked={changepreiumclicked}
                                    nonRedPremTextBoxValue={nonRedPremTextBoxValue}
                                    nonRedPremiumUpdate={nonRedPremiumUpdate}
                                    changeNonRedPremiumUpdate={changeNonRedPremiumUpdate}
                                    nonRedPremFlag={nonRedPremFlag}
                                    fxRateTextBoxValue={fxRateTextBoxValue}
                                    fxRateUpdate={fxRateUpdate}
                                    changeFxRateUpdate={changeFxRateUpdate}
                                    fxRateFlag={fxRateFlag}
                                    gasOilTextBoxValue={gasOilTextBoxValue}
                                    gasOilUpdate={gasOilUpdate}
                                    changeGasOilUpdate={changeGasOilUpdate}
                                    gasOilFlag={gasOilFlag}
                                    EurUsDValue={EurUsDValue}
                                    eurUsdFlag={eurUsdFlag}
                                    disableEurUsdFlag={disableEurUsdFlag}
                                    twtgalValue={twtgalValue}
                                    twtgalFlag={twtgalFlag}
                                    disableTwtGalFlag={disableTwtGalFlag}
                                ></Spreadsheets>
                            ) : (
                                <> </>
                            )}
                            {errorFlag ? (
                                <div>
                                    {" "}
                                    <h1
                                        style={{
                                            textAlign: "center",
                                            color: "red",
                                        }}
                                    >
                                        The selected sheet is currently unavailable
                                    </h1>
                                </div>
                            ) : (
                                <> </>
                            )}
                        </div>
                        {loaderFlag ? (
                            <> </>
                        ) : (
                            <div>
                                <img
                                    src={require("../images/loader.svg").default}
                                    style={{
                                        alignContent: "center",
                                        display: "block",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        width: "50%",
                                        height: window.innerHeight / 5,
                                    }}
                                    alt=""
                                />
                            </div>
                        )}
                        {props.showLoader ? (
                            <div>
                                <img
                                    src={require("../images/loader.svg").default}
                                    style={{
                                        alignContent: "center",
                                        display: "block",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        width: "50%",
                                        height: "200px",
                                    }}
                                    alt=""
                                />
                            </div>
                        ) : (
                            <> </>
                        )}
                        {paperEveningFlag ? (
                            <> </>
                        ) : (
                            <div>
                                <img
                                    src={require("../images/loader.svg").default}
                                    style={{
                                        alignContent: "center",
                                        display: "block",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        width: "50%",
                                        height: "200px",
                                    }}
                                    alt=""
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
