export const pricingInfoRedStatus = [
    "Not Entered",
    "Auto Rolled not Published",
    "Auto Rolled",
    "Unknown",
];

export const checkForRollOver = "/CheckForRollOver?";
export const sendEodDataToSql = "/SendEODDataToSQL?";
export const eodSpreadSheetDropDown = "/EODSpreadSheetDropDown?";
export const getPublicationCutoff = "/GetPublicationCutoff?";
export const priceSubmissionStatus = "/priceSubmissionStatus";
export const timeoutError = "/eodpricing/timeoutError";
export const sendEODArchive = "/SendEODArchive?";
export const fetchCredits = "/FetchCredits?";
export const newTickerGeneration = "/NewTickerGeneration?";
export const fetchUOME = "/FetchUCOME?";
export const paperEveningNumbers = [
    "EUBIOFUELRMEREDPAPER",
    "EUBIOFUELRSOPAPER",
    "EUBIOFUELFAMERED0CPAPER",
];

export const sheetNames = {
    EUETHANOLEVENINGNUMBERS: "EU Ethanol Evening Numbers",
    PAPEREVENINGNUMBERS: "Paper Evening Numbers",
    PHYSICALEVENINGNUMBERS: "Physical Evening Numbers",
    EODUSMETHANOL: "EOD US Methanol",
    NONREDPME: "Non RED PME",
    NONREDSME: "Non RED SME",
    NONREDFAME0: "Non RED FAME 0",
    NONREDRME: "Non RED RME",
    PHYSICALEVENINGEUBIOFUELS: "Physical Evening numbers EU Biofuels",
    PMEOVERFME: "PME Discount over FAME 0 ($)",
    T2METHANOLFOBROTTERDAM: "T2 Methanol FOB Rotterdam",
    FOBHOUSTONMETHANOL: "FOB Houston Methanol",
    T2METHANOLFOB: "T2 Methanol FOB",
    FOBHOUSTON: "FOB Houston",
    REDPME: "RED PME",
    REDSME: "RED SME",
    SMEPREMFME: "SME Premium over FAME 0 ($)",
    EUCARBONCREDIT: "Carbon Credits Europe EOD",
    USCARBONCREDIT: "Carbon Credits US EOD",
    UCOMM: "UCO UCOME EOD",
    UCOMMSHEETONE: "ICE Low-Sulphur-Gasoil Swap",
    UCOMMSHEETTWO: "RED FAME 0 Paper",
    UCOMMSHEETTHREE: "RED UCOME PREMIUM OVER FAME0 $/mt",
    UCOMMSHEETFOUR: "FLAT PRICE RED UCO CIF ARA $/mt",
    UCOMMSHEETFIVE: "FLAT PRICE UCOME 87% UK/NLDC FOB ARA $/mt",
};
