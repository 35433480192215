import { React, useEffect, useState, useRef } from "react";
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import api from "../Services/Api";
import * as constants from "../constants";
import { Button, Modal } from "react-bootstrap";
import toastr from "reactjs-toastr";
import "reactjs-toastr/lib/toast.css";

export default function CarbonCreditForm(props) {
    const [productGroupValue, setProductGroupValue] = useState("");
    const [tickerName, setTickerName] = useState("");
    const productGroupValueError = useRef(false);
    const newProductGpName = useRef(false);
    const newProductGpNameErrorMsg = useRef("");
    const tickerError = useRef(false);
    const tickerErrorMsg = useRef("");
    const [disablePrice, setDisablePrice] = useState(false);
    const nameRegex = /^[A-Z0-9]+$/;
    const { baseapi } = api();
    const [showModal, setShowModal] = useState(false);
    const [showAddNewTicker, setShowAddNewTicker] = useState(false);
    const [checkError, setError] = useState(false);

    useEffect(() => {
        if (props.productGroupNameList.indexOf(productGroupValue) === -1) {
            newProductGpName.current = true;
        } else {
            newProductGpName.current = false;
        }
    }, [productGroupValue, newProductGpName, props.productGroupNameList]);
    function validateGpName() {
        setProductGroupValue((state) => {
            if (state.length === 0) {
                productGroupValueError.current = true;
                newProductGpNameErrorMsg.current = "Product Group Name is required";
            } else if (state.length > 50) {
                productGroupValueError.current = true;
                newProductGpNameErrorMsg.current = "Maximum 50 characters are allowed";
            } else if (!nameRegex.test(state)) {
                productGroupValueError.current = true;
                newProductGpNameErrorMsg.current = "Invalid Product Group Name";
            } else {
                productGroupValueError.current = false;
                newProductGpNameErrorMsg.current = "";
            }
            return state;
        });
        setError((error) => !error);
    }
    function validatetTickerName() {
        setTickerName((state) => {
            if (state.length === 0) {
                tickerError.current = true;
                tickerErrorMsg.current = "Ticker Name is required";
            } else if (state.length > 50) {
                tickerError.current = true;
                tickerErrorMsg.current = "Maximum 50 characters are allowed";
            } else if (state.length < 3) {
                tickerError.current = true;
                tickerErrorMsg.current = "Minimum 3 characters are required";
            } else if (!nameRegex.test(state)) {
                tickerError.current = true;
                tickerErrorMsg.current = "Invalid Ticker Name";
            } else {
                tickerError.current = false;
                tickerErrorMsg.current = "";
            }
            return state;
        });
        setError((error) => !error);
    }
    const reset = () => {
        setProductGroupValue("");
        setTickerName("");
        productGroupValueError.current = false;
        newProductGpNameErrorMsg.current = "";
        tickerError.current = false;
        tickerErrorMsg.current = "";
        setError((error) => !error);
    };
    const carbonPriceApi = async () => {
        const formData = {
            eod_sheet: props.sheetName,
            date: props.selectedDate,
            product_group_name: productGroupValue,
            scb_ticker_name: tickerName,
            priceclose: 0.0,
            new_record: newProductGpName.current,
        };
        const response = await baseapi(constants.newTickerGeneration, "POST", formData);
        if (response.status_code === 200) {
            response.status === false ? await props.fetchCarbonCreditData() : setShowModal(true);
        } else {
            toastr.error("Error Message", "Title");
        }
        reset();
        setDisablePrice(false);
    };
    const postCarbonCreditPRice = () => {
        setDisablePrice(true);
        validateGpName();
        validatetTickerName();
        setTimeout(() => {
            if (productGroupValueError.current === false && tickerError.current === false) {
                carbonPriceApi();
            } else {
                setDisablePrice(false);
            }
        }, 0);
    };

    const handleModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            {!showAddNewTicker ? (
                <div className="row">
                    <div className="col-sm-3 col-lg-3 col-md-3 col-xs-3">
                        <button
                            className="btn btn-submit"
                            disabled={!props.loaderFlag}
                            onClick={() => {
                                setShowAddNewTicker(true);
                                reset();
                            }}
                        >
                            Add New Ticker
                        </button>
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3 col-xs-3"></div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-sm-3 col-lg-3 col-md-3 col-xs-3">
                        <div className="row">
                            <label> Product Group Name</label>
                            <div>
                                <Combobox
                                    className="form-control "
                                    title="Only Uppercase Letters and Numbers are allowed for Product Group Name"
                                    value={productGroupValue}
                                    hideEmptyPopup={true}
                                    data={props.productGroupNameList}
                                    autoFocus={productGroupValueError.current}
                                    onChange={(productGroupValue) => {
                                        setProductGroupValue(productGroupValue);
                                        validateGpName();
                                    }}
                                    placeholder="Enter New Product Group"
                                />
                            </div>
                            <div className="error">
                                {productGroupValueError.current
                                    ? newProductGpNameErrorMsg.current
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3 col-xs-3">
                        <div className="row">
                            <label> SCB Ticker Name</label>
                            <div>
                                <input
                                    type="text"
                                    title="Only Uppercase Letters and Numbers are allowed for Ticker Name"
                                    className="fieldDesign form-control"
                                    onChange={(event) => {
                                        setTickerName(event.target.value);
                                        validatetTickerName();
                                    }}
                                    value={tickerName}
                                />
                            </div>
                            <div className="error">
                                {tickerError.current && (checkError || !checkError)
                                    ? tickerErrorMsg.current
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3 col-xs-3">
                        <div className="row">
                            <div>
                                <button
                                    className="btn btn-submit mar-22"
                                    onClick={postCarbonCreditPRice}
                                    disabled={disablePrice}
                                    id="submitCarbonCreditPrice"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-lg-3 col-md-3 col-xs-3">
                        <div className="row">
                            <div>
                                <button
                                    className="btn btn-submit mar-22"
                                    onClick={() => {
                                        setShowAddNewTicker(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                aria-labelledby="newTickerError"
                centered
                show={showModal}
                onHide={handleModal}
                backdrop="static"
            >
                <Modal.Body>
                    <p> Ticker Name already exists!!!</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        className="btn-success"
                        style={{ alignContent: "center" }}
                        onClick={handleModal}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
