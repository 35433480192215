import { useNavigate } from "react-router-dom";
import * as constants from "../constants";
import { msalInstance } from "../Msal/msalConfig";
import { acquireAccessToken } from "../Msal/tokenGenerate";

const Api = () => {
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASEURL;
    const baseapi = async (url, method, body) => {
        const res = await acquireAccessToken(msalInstance);
        const valid = [];
        valid.push(res.idToken);
        valid.push(res.account.username);
        const URL = baseURL + url;
        const response = await fetch(URL, {
            redirect: "manual",
            method: method,
            headers: {
                Authorization: `Bearer ${valid[0]}`,
                userEmail: valid[1],
            },
            body: JSON.stringify(body),
        })
            .then((response) => {
                if (response.type === "opaqueredirect") {
                    navigate(constants.timeoutError, {
                        state: { id: true, name: "unauthorized" },
                    });
                    return response.json();
                }
                return response.json();
            })
            .catch(function xyz(error) {
                return error;
            });

        return response;
    };
    return { baseapi };
};
export default Api;
