import { Header } from "./components/Header";
import { Controls } from "./components/Controls";
import { Footer } from "./components/Footer";
import { useEffect, useState } from "react";
import ErrorBound from "./components/ErrorBound";
import GC from "@grapecity/spread-sheets";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css";
import api from "./Services/Api";
import * as constants from "./constants";
import { useNavigate } from "react-router-dom";
import "./App.css";
function App() {
    const navigate = useNavigate();
    const [flag, setflag] = useState(false);
    const [loader, setLoader] = useState(false);

    const [onCompleteFlag, setonCompleteflag] = useState(false);
    const [premiumTextbox, setPremiumTextbox] = useState(false);
    const [sendEODbutton, setSendEODbutton] = useState(false);

    const [date, setDate] = useState();
    const [eodSheet, setEodSheet] = useState();

    const [refreshTimer, setRefreshTimer] = useState(false);

    const [spreadArr, setSpreadArr] = useState([]);
    const [nonRedPrem, setNonRedPrem] = useState("");
    const [eodList, setEodList] = useState([]);
    const [email, setEmail] = useState("");
    const { baseapi } = api();
    useEffect(() => {
        async function fetchData() {
            const response = await baseapi(constants.eodSpreadSheetDropDown, "GET");
            if (response.status_code === 200) {
                setEodList(response.data.sheet_list);
                setEmail(response.data.email);
            } else if (response && response.status_code === 401) {
                navigate("/eodpricing/authenticationError", {
                    state: { id: true, name: "unauthorized" },
                });
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);
    const cutOff = () => {
        setRefreshTimer(true);
    };

    const settingNonRedPrem = (nonRedPremValue) => {
        setNonRedPrem(nonRedPremValue);
    };

    const onLoad = (flag, spreadarr, date, eodSheet, event, value, value2) => {
        setSpreadArr(spreadarr);
        setflag(flag);
        setDate(date);
        setEodSheet(eodSheet);

        if (event === "addPremium") {
            spreadarr.forEach((spread, index) => {
                const sheet = spread.getSheet(0);
                const name = sheet.name();
                if (name === "T2 Ethanol ARA") {
                    for (let index = 0; index < sheet.getRowCount(); index++) {
                        for (let index1 = 1; index1 < 3; index1++) {
                            const val =
                                parseFloat(sheet.getValue(index, index1)) + parseFloat(value);
                            sheet.setValue(index, index1, val);
                        }
                    }
                }
            });
        }
        if (event === "usMethanolEurUsdUpdate") {
            spreadarr.forEach((spread, index) => {
                const sheet = spreadarr[2].getSheet(0);
                const T2MethanolFOBRotterdam = spreadarr[1].getSheet(0);

                const FOBHouston = spreadarr[3].getSheet(0);
                const sheet1 = spreadarr[4].getSheet(0);
                const name = sheet.name();

                if (name === "T2 Methanol FOB") {
                    for (let index = 0; index < sheet.getRowCount(); index++) {
                        for (let index1 = 1; index1 < 3; index1++) {
                            const val = T2MethanolFOBRotterdam.getValue(index, index1) * value;

                            sheet.setValue(index, index1, val);
                            sheet1.setFormula(
                                index,
                                1,
                                "IF(AND(" +
                                    sheet.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                                    ">0," +
                                    FOBHouston.getValue(
                                        index,
                                        3,
                                        GC.Spread.Sheets.SheetArea.viewport
                                    ) +
                                    ">0)," +
                                    FOBHouston.getValue(
                                        index,
                                        3,
                                        GC.Spread.Sheets.SheetArea.viewport
                                    ) +
                                    "-" +
                                    sheet.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                                    ",0)",
                                GC.Spread.Sheets.SheetArea.viewport
                            );
                        }
                    }
                }
            });
        }

        if (event === "UcommFormulaRows") {
            spreadarr.forEach((spread, index) => {
                const sheet1 = spreadarr[0].getSheet(0);
                const sheet2 = spreadarr[1].getSheet(0);
                const sheet3 = spreadarr[2].getSheet(0);
                const sheet4 = spreadarr[3].getSheet(0);

                const name = sheet4.name();

                if (name === "FLAT PRICE RED UCO CIF") {
                    for (let index = 0; index < sheet4.getRowCount(); index++) {
                        for (let index1 = 1; index1 < 3; index1++) {
                            sheet4.setFormula(
                                index,
                                2,
                                sheet4.getValue(index, 1, GC.Spread.Sheets.SheetArea.viewport) +
                                    "+(" +
                                    sheet3.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                                    "+" +
                                    sheet2.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                                    "+" +
                                    sheet1.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                                    ")/2",
                                GC.Spread.Sheets.SheetArea.viewport
                            );
                        }
                    }
                }
            });
        }

        if (event === "usMethanolTwtGalUpdate") {
            spreadarr.forEach((spread, index) => {
                const sheet = spreadarr[3].getSheet(0);
                const FOBHoustonMethanol = spreadarr[0].getSheet(0);
                const T2MethanolFOB = spreadarr[2].getSheet(0);
                const sheet1 = spreadarr[4].getSheet(0);

                const name = sheet.name();

                if (name === "FOB Houston") {
                    for (let index = 0; index < sheet.getRowCount(); index++) {
                        for (let index1 = 1; index1 < 3; index1++) {
                            const val = FOBHoustonMethanol.getValue(index, index1) * value;

                            sheet.setValue(index, index1, val);

                            sheet1.setFormula(
                                index,
                                1,
                                "IF(AND(" +
                                    T2MethanolFOB.getValue(
                                        index,
                                        3,
                                        GC.Spread.Sheets.SheetArea.viewport
                                    ) +
                                    ">0," +
                                    sheet.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                                    ">0)," +
                                    sheet.getValue(index, 3, GC.Spread.Sheets.SheetArea.viewport) +
                                    "-" +
                                    T2MethanolFOB.getValue(
                                        index,
                                        3,
                                        GC.Spread.Sheets.SheetArea.viewport
                                    ) +
                                    ",0)",
                                GC.Spread.Sheets.SheetArea.viewport
                            );
                        }
                    }
                }
            });
        }

        if (event === "addGasOil" || event === "addFxRate") {
            spreadarr.forEach((spread, index) => {
                const sheet0 = spreadarr[0].getSheet(0);
                const name = sheet0.name();
                const sheet2 = spreadarr[2].getSheet(0);

                if (name === "Physical Evening numbers EU Biofuels") {
                    let fxValue = value2;
                    let gasOilValue;
                    if (value === "" || value === null) {
                        gasOilValue = 0.0;
                    } else {
                        gasOilValue = parseFloat(value);
                    }

                    if (fxValue === 0 || fxValue === null || fxValue === "") {
                        sheet0.setValue(6, 2, null);
                        sheet0.setValue(7, 2, null);
                    } else {
                        fxValue = parseFloat(value2);
                        const physicalPremGasoilVal = parseFloat(sheet0.getValue(3, 2));
                        const spotVal = parseFloat(sheet2.getValue(0, 1));
                        const comeFobVal = (gasOilValue + physicalPremGasoilVal) / fxValue;
                        const tmeFobVal = (gasOilValue + physicalPremGasoilVal + spotVal) / fxValue;

                        sheet0.setValue(6, 2, comeFobVal);
                        sheet0.setValue(7, 2, tmeFobVal);
                    }
                }
            });
        }

        if (event === "phyEvengSheetUpdate") {
            spreadarr.forEach((spread, index) => {
                const sheet0 = spreadarr[0].getSheet(0);
                const name = sheet0.name();

                if (name === "Physical Evening numbers EU Biofuels") {
                    const valArr = value.split(" ");
                    const vallArr2 = value2.split(" ");
                    const fxValue = parseFloat(valArr[1]);
                    let gasOilValue = parseFloat(valArr[0]);
                    if (valArr[0] === "" || gasOilValue === null) {
                        gasOilValue = 0.0;
                    }

                    const physicalPremGasoilVal = parseFloat(vallArr2[0]);
                    const spotVal = parseFloat(vallArr2[1]);
                    const comeFobVal = (gasOilValue + physicalPremGasoilVal) / fxValue;
                    const tmeFobVal = (gasOilValue + physicalPremGasoilVal + spotVal) / fxValue;

                    sheet0.setValue(6, 2, comeFobVal);
                    sheet0.setValue(7, 2, tmeFobVal);
                }
            });
        }
    };
    const onComplete = (data) => {
        setonCompleteflag(data);
    };

    const onClickSendEOD = () => {
        setSendEODbutton(true);
    };
    const onClickUpdate = () => {
        setSendEODbutton(false);
    };
    const showLoader = (data) => {
        setLoader(data);
    };
    const disablePremiumTextbox = (data) => {
        setPremiumTextbox(data);
    };
    return (
        <div>
            <ErrorBound>
                <Header email={email} />
                <Controls
                    eodList={eodList}
                    onLoad={onLoad}
                    onCompleteFlag={onCompleteFlag}
                    settingNonRedPrem={settingNonRedPrem}
                    showLoader={loader}
                    onComplete={onComplete}
                    premiumTextbox={premiumTextbox}
                    disablePremiumTextbox={disablePremiumTextbox}
                    cutOff={cutOff}
                    spreadArray={spreadArr}
                    date={date}
                    eodSheet={eodSheet}
                    nonRedPrem={nonRedPrem}
                    sendEODbutton={sendEODbutton}
                    onClickUpdate={onClickUpdate}
                />
                <Footer
                    flag={flag}
                    spreadArray={spreadArr}
                    date={date}
                    eodSheet={eodSheet}
                    nonRedPrem={nonRedPrem}
                    onComplete={onComplete}
                    disablePremiumTextbox={disablePremiumTextbox}
                    showLoader={showLoader}
                    refreshTimer={refreshTimer}
                    onClickSendEOD={onClickSendEOD}
                />
            </ErrorBound>
        </div>
    );
}

export default App;
