import GC from "@grapecity/spread-sheets";
import React from "react";
import { Spreadsheet } from "./Spreadsheet";

const SpreadJSKey = process.env.REACT_APP_SpreadJSKEY;
GC.Spread.Sheets.LicenseKey = SpreadJSKey;

export const Spreadsheets = (props) => {
    return (
        <>
            <Spreadsheet {...props} />
        </>
    );
};
