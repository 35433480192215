import React, { Component } from "react";

export default class ErrorBound extends Component {
    constructor() {
        super();
        this.state = {
            error: null,
        };
    }
    static getDerivedStateFromError() {
        return { error: true };
    }
    render() {
        return (
            <div>
                {this.state.error ? (
                    <div style={{ marginTop: "50px" }}>
                        <h1 style={{ textAlign: "center", color: "red" }}>
                            {" "}
                            Oops! Something has gone wrong.
                        </h1>
                        <p style={{ textAlign: "center", color: "red" }}>
                            This sheet is unable to display. Please Try again
                        </p>
                    </div>
                ) : (
                    this.props.children
                )}
            </div>
        );
    }
}
