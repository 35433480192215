import React, { Suspense, lazy, useEffect, useState } from "react";
import ReactDOM from "react-dom";
const Pricing = lazy(() => import("./pricing/Home"));
import App from "./App";
import TimeoutError from "../src/Errors/TimeoutError";
import AuthenticationError from "../src/Errors/AuthenticationError";
import { Spinner } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, msalInstance } from "./Msal/msalConfig";
import Layout from "./Layout/Layout";

function Init() {
    const [show, setShow] = useState(false);
    function handleResponse(response) {
        if (response === null) {
            msalInstance.loginRedirect(msalConfig.loginRequest); // not logged in
        } else {
            msalInstance.setActiveAccount(response.account);
            setShow(true);
        }
    }
    useEffect(() => {
        (async () => {
            const tokenResponse = await msalInstance.handleRedirectPromise();
            handleResponse(tokenResponse);
        })();
    }, []);
    return (
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                {show ? (
                    <BrowserRouter>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route path="/" element={<Layout />} />
                                <Route path="/eodpricing" element={<App />} />
                                <Route
                                    path="/eodpricing/authenticationError"
                                    element={<AuthenticationError />}
                                />
                                <Route path="/eodpricing/timeoutError" element={<TimeoutError />} />
                                <Route path="/recpricing" element={<Pricing page={"Rec"} />} />
                                <Route path="/irecpricing" element={<Pricing page={"Irec"} />} />
                                <Route
                                    path="/forestrypricing"
                                    element={<Pricing page={"Forestry"} />}
                                />
                                <Route
                                    path="/renewablespricing"
                                    element={<Pricing page={"Renewables"} />}
                                />
                            </Routes>
                        </Suspense>
                    </BrowserRouter>
                ) : (
                    <Loader />
                )}
            </MsalProvider>
        </React.StrictMode>
    );
}
function Loader() {
    return (
        <>
            <div className="row vh-100">
                <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 d-flex justify-content-center align-items-center align-self-center">
                    <Spinner animation="grow" variant="danger" />
                </div>
            </div>
        </>
    );
}
ReactDOM.render(<Init />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
