import { msalConfig } from "./msalConfig";

export const acquireAccessToken = async (msalInstance) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    const username = activeAccount || accounts[0];

    const request = {
        ...msalConfig.loginRequest,
        // account: account,
        account: username,
    };
    try {
        if (!activeAccount && accounts.length === 0) {
            /*
             * User is not signed in. Throw error or wait for user to login.
             * Do not attempt to log a user in outside of the context of MsalProvider
             */
            throw Error;
        }

        return await msalInstance.acquireTokenSilent(request).catch((error) => {
            return msalInstance.acquireTokenRedirect(request);
        });
    } catch (error) {
        return msalInstance.acquireTokenRedirect(request);
    }
};
