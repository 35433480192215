import React from "react";
import { useLocation, Navigate } from "react-router-dom";

export default function Errors() {
    const location = useLocation();

    return (
        <>
            {location.state ? (
                <div style={{ margin: "10px" }}>
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <img src={require("../images/scb-logo.png").default} />
                    </div>
                    <div className="form-input">
                        <div className="form-group">
                            <div
                                className="col-sm-9 text-center"
                                style={{
                                    width: "90%",
                                    position: "absolute",
                                    top: "33%",
                                }}
                            >
                                <h2
                                    style={{
                                        margin: "1% !important",
                                        fontWeight: "900",
                                        color: "#15BD78",
                                        fontSize: " -webkit-xxx-large",
                                        textAlign: "left !important",
                                    }}
                                >
                                    Unauthorized
                                </h2>
                                <br />
                                <p className="message-text" style={{ fontSize: "24px" }}>
                                    You do not have permissions to view this page.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <Navigate to="/eodpricing" />
                </>
            )}
        </>
    );
}
