export default function validateInfo(values, UpdateType) {
    const errors = {};
    const current = new Date();
    if (!values.EODFunction) {
        errors.EODFunction = "Please Select EOD Sheet";
    }
    if (UpdateType === "Historical") {
        if (values.PriceEntryDate.toDateString() === current.toDateString()) {
            errors.PriceEntryDate = "Please Select Past Date";
        }
    }

    return errors;
}
